.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 130px;
  width: 100%;
  padding: 0.5rem 0rem;
  background-color: #1C1C1C;
}

@media screen and (min-width: 768px) {
  .navigation {
    position: absolute;
    background-color: transparent;
  }
}

.navigation-menu {
  margin-left: auto;
  margin-right: 32px;
}

@media screen and (min-width: 1300px) {
  .navigation-menu {
    margin-top: 120px;
    margin-right: 120px;
  }
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  display: block;
  width: 100%;
}

.navigation-logo {
  /* width: 100px; */
  height: 60px;
  margin-left: 48px;
  
}

@media screen and (min-width: 1300px) {
  .navigation-logo {
    height: 100px;
    margin-top: 120px;
    margin-left: 120px;
  }
}

.hamburger {
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  cursor: pointer;
  position: absolute;
  top: 60%;
  right: 35px;
  transform: translateY(-50%);
  display: none;
  
}

/* TODO - prepsat max-width */
@media screen and (max-width: 550px) {
  .hamburger {
    display: block;
  }

  .navigation-menu {
    opacity: 0;
    transition: all 0.5s ease-in;
  }

  .navigation-menu.expanded {
    opacity: 0.8;
    transition: all 0.5s ease-out;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    top: 100px;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: white;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: none;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 0.5rem 0;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }
}

.navigation-link {
  font-size: 22px;
  color: #fff;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.navigation-link:hover {
  text-decoration: none;
}

@media screen and (min-width: 550px) {
  .navigation-link {
    font-size: 40px;
  }
}

@media screen and (min-width: 1300px) {
  .navigation-link {
    font-size: 60px;
  }
}

/* animated hamburger menu */
.hamburger-label {
  width: 40px;
  height: 5px;
  background-color: white;
  position: absolute;
  cursor: pointer;
}

  .hamburger-label::before,
  .hamburger-label::after {
    content: "";
    width: 40px;
    height: 5px;
    background-color: white;
    position: absolute;
    display: block;
    color: red;
    transition: all 0.4s cubic-bezier(1, 0, 0, 1);
  }

  .hamburger-label::before {
    top: -250%;
    left: 0%;
    transform: rotateZ(0deg);
    transform-origin: left top;
  }

  .hamburger-label::after {
    bottom: -250%;
    left: 0%;
    transform: rotateZ(0deg);
    transform-origin: left bottom;
  }


#toggle:checked ~ .hamburger-label::before {
  content: "";
  background-color: red;
  transform: rotateZ(41deg);
  left: 10%;
}

#toggle:checked ~ .hamburger-label::after {
  content: "";
  background-color: red;
  transform: rotateZ(-41deg);
  left: 10%;
}

#toggle:checked ~ .hamburger-label {
  background-color: #1C1C1C;
  
}

#toggle ~ .hamburger-label {
  transition: all 0.4s cubic-bezier(1, 0, 0, 1);
}

.hamburger-input {
  display: none;
}
