@font-face {
  font-family: "Lansbury";
  src: url("./fonts/fonts/Lansbury\ FG.eot");
  src: url("./fonts/fonts/Lansbury\ FG.woff") format("woff"),
  url("./fonts/fonts/Lansbury\ FG.ttf") format("ttf");
  }

@font-face {
  font-family: "Hardinge";
  src: url("./fonts/fonts/hardinge.otf") format("otf");
  src: url("./fonts/fonts/hardinge.woff") format("woff"),
  url("./fonts/fonts/hardinge.ttf") format("ttf");
  }

body {
  margin: 0;
  /* font-family: 'Lansbury', 'Open-sans', sans-serif; */
  font-family: 'Hardinge', 'Open-sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
