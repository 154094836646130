.store-container {
  background-color: #1C1C1C;
  color: white;
  padding: 0 32px;
  padding-bottom: 100px;
}

.store-headline-container {
  text-align: center;
  margin-bottom: 32px;
}

.store-headline {
  margin: 0;
  color: white;
  font-size: 50px;
  /* z-index: 10; */
  text-decoration: none;
  font-weight: 500;
}

.store-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
}

@media screen and (min-width: 550px) {
  .store-product-container {
    flex-direction: row-reverse;
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 768px) {
  .store-product-container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }
}

.store-text-container {
  text-align: center;
}

@media screen and (min-width: 550px) {
  .store-text-container {
    text-align: left;
    margin-left: 26px;
  }
}

/* .store-title { */
  /* font-size: 30px; */
  /* font-family: Lansbury; */
/* } */

.store-doomlighter {
  width: 200px;
}

.store-text {
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
}

.store-img {
  opacity: .9;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.store-img:hover {
  opacity: 1;
}

.store-check-container {
  text-align: center;
  margin-bottom: 180px;
}

@media screen and (min-width: 768px) {
  .store-check-container {
    position: relative;
    left: -130px;
  }
}

@media screen and (min-width: 1024px) {
  .store-check-container {
    left: -170px;
  }
}

.store-check-container-text {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
}

.store-check-container-img {
  width: 120px;
}

.store-sites-container {
  text-align: center;
}

.store-sites-img {
  width: 60px;
  margin: 20px
}

