.work-container {
  background-color: #1C1C1C;
  /* height: 100%; */
  padding-top: 32px;
  padding-bottom: 100px;
}

@media screen and (min-width: 1024px) {
  .work-container {
    padding-bottom: 400px;
  }
}

.work-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 550px) {
  .work-gallery {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (min-width: 1024px) {
  .work-gallery {
    justify-content: space-evenly;
    margin: 0 120px;
  }
}

.work-gallery-item {
  margin: 16px;
  text-align: center;
}

.work-gallery-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  color: white;
}

.work-gallery-item-img {
  width: 200px;
  max-height: 400px;
  border-radius: 25px;
  opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.work-gallery-item-img:hover {
  opacity: .5;
}

@media screen and (min-width: 1024px) {
  .work-gallery-item-img {
    /* width: 300px; */
    width: 400px;
  }
}

.work-headline-container {
  text-align: center;
  margin-bottom: 32px;
}

.work-headline {
  color: white;
  font-size: 50px;
  z-index: 10;
  text-decoration: none;
  font-weight: 500;
}
