/* @media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
} */

.main-container {
  /* display: flex; */
}

a:hover,
a:focus {
  color: #fff;
}

#contact {
  /* background: none; */
}