


.contact-image-container {
  background-image: url(../../assets/contact-mini2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 32px 20px;
}

@media screen and (min-width: 768px) {
  .contact-image-container {
    padding-bottom: 100px;
  }
}

.contact-link-container {
  text-align: center;
}

.contact-link {
  color: white;
  font-size: 50px;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
  color: white;
  text-underline-offset: 4px;
}

.contact-text {
  color: white;
  text-align: center;
  font-size: 22px;
  padding: 0 16px 32px;
  letter-spacing: 2px;
}

.contact-text-location {
  color: white;
  text-align: center;
  font-size: 32px;
  letter-spacing: 2px;
  margin-bottom: 0;
}

@media screen and (min-width: 550px) {
  .contact-text {
    font-size: 32px;
    padding-bottom: 48px;
  }
}