.main-image-container {
  /* position: absolute; */
  /* position: relative; */
  /* top: 0; */
  /* z-index: -1; */
}

@media screen and (min-width: 1059px) {
  .main-image-container {
    /* top: -40px; */
  }
}

.main-image-headline {
  position: absolute;
  width: 300px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 70px;
}

.navigation-img {
  width: 100%;
  /* position: absolute; */
  /* position: relative; */
  /* top: 0; */
  /* z-index: -1; */
}

@media screen and (min-width: 1059px) {
  .navigation-img {
    /* top: -40px; */
  }
}
